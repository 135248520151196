import Title from 'antd/lib/typography/Title';
import AlimenteazaCombustibil from 'components/AlimenteazaCombustibil/AlimenteazaCombustibil';
import InregistreazaCentruDeProfit from 'components/InregistreazaCentruDeProfit/InregistreazaCentruDeProfit';
import InregistreazaEchipament from 'components/InregistreazaEchipament/InregistreazaEchipament';
import InregistreazaFoaieDeConsum from 'components/InregistreazaFoaieDeConsum/InregistreazaFoaieDeConsum';
import InregistreazaSalariat from 'components/InregistreazaSalariat/InregistreazaSalariat';
import IntroducereDateContor from 'components/IntroducereDateContor/IntroducereDateContor';
import LoginPage from 'components/LoginPage/LoginPage';
import VizualizareCentreDeProfit from 'components/VizualizareCentreDeProfit/VizualizareCentreDeProfit';
import VizualizareEchipamente from 'components/VizualizareEchipamente/VizualizareEchipamente';
import VizualizareSalariati from 'components/VizualizareSalariati/VizualizareSalariati';
import WelcomePage from 'components/WelcomePage/WelcomePage';
import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { userService } from 'services/UserService';

import './App.scss';

function AppHeader() {
    return (
        <div className="header">
            <Title>Freddie</Title>
        </div>
    );
}

function App() {
    return (
        <BrowserRouter>
            <AppHeader />
            <Switch>
                <Route path="/login" component={LoginPage} />
                <Route path="/" exact render={(props) => showPageIfLoggedIn(<WelcomePage />, props)}></Route>
                <Route path="/welcome" render={(props) => showPageIfLoggedIn(<WelcomePage />, props)}></Route>
                <Route
                    path="/inregistrare-salariat"
                    render={(props) => showPageIfLoggedIn(<InregistreazaSalariat />, props)}
                ></Route>
                <Route
                    path="/salariati"
                    render={(props) => showPageIfLoggedIn(<VizualizareSalariati />, props)}
                ></Route>
                <Route
                    path="/echipamente"
                    render={(props) => showPageIfLoggedIn(<VizualizareEchipamente />, props)}
                ></Route>
                <Route
                    path="/centre-de-profit"
                    render={(props) => showPageIfLoggedIn(<VizualizareCentreDeProfit />, props)}
                ></Route>
                <Route
                    path="/inregistrare-echipament"
                    render={(props) => showPageIfLoggedIn(<InregistreazaEchipament />, props)}
                ></Route>
                <Route
                    path="/inregistrare-centru-de-profit"
                    render={(props) => showPageIfLoggedIn(<InregistreazaCentruDeProfit />, props)}
                ></Route>
                <Route
                    path="/inregistrare-foaie-de-consum"
                    render={(props) => showPageIfLoggedIn(<InregistreazaFoaieDeConsum />, props)}
                ></Route>
                <Route
                    path="/alimentare-combustibil"
                    render={(props) => showPageIfLoggedIn(<AlimenteazaCombustibil />, props)}
                ></Route>
                <Route
                    path="/introducere-contor-final-luna"
                    render={(props) => showPageIfLoggedIn(<IntroducereDateContor />, props)}
                ></Route>
            </Switch>
        </BrowserRouter>
    );

    function showPageIfLoggedIn(page: JSX.Element, props: any): JSX.Element {
        return userService.isAuthenticated ? page : RedirectToLogin(props);
    }

    function RedirectToLogin(props: any): JSX.Element {
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: { from: props.location },
                }}
            />
        );
    }
}

export default App;
