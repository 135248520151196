import { Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';

import FormularInregistrareSalariat from './FormularInregistrareSalariat/FormularInregistrareSalariat';
import './InregistreazaSalariat.scss';

function InregistreazaSalariat(): JSX.Element {
    return (
        <div className="inregistreaza-salariat">
            <div className="inregistreaza-salariat__box">
                <Typography>
                    <Title>Inregistreaza Salariat</Title>
                    <Title level={5}>Completeaza formularul de mai jos pentru a inregistra un angajat</Title>
                    <FormularInregistrareSalariat />
                </Typography>
            </div>
        </div>
    );
}

export default InregistreazaSalariat;
