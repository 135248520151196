import { notification } from "antd";
import { AxiosResponse } from "axios";
import { LoginFormData } from "components/LoginPage/LoginFormData";
import Cookies from "universal-cookie/es6";
import getIdentityServerHttpClient from "./IdentityServerHttpClient";

export const userService = {
	isAuthenticated: bearerCookieExists(),
	getToken: getToken,
	login: login
}

function login(loginFormData: LoginFormData, history: any) {
	const identityServerClient = getIdentityServerHttpClient();

	identityServerClient
		.post('/users/authenticate', {
			username: loginFormData.username,
			password: loginFormData.password,
		})
		.then(function (response: AxiosResponse) {
			addBearerTokenToLocalStorageWithExpiry(response.data.token, new Date(response.data.expirationTime));

			userService.isAuthenticated = true;

			notification.success({
				message: 'Info',
				description: 'Autentificarea a fost efectuata cu succes.'
			});

			history.push('/welcome');
		})
		.catch(function () {
			notification.error({
				message: 'Eroare de la server',
				description: 'Username sau parola sunt gresite',
			});
		});
}

function addBearerTokenToLocalStorageWithExpiry(bearerToken: string, expirationTime: Date) {
	console.warn(expirationTime);

	const cookies = new Cookies();

	cookies.set('bearer', bearerToken, { path: '/', expires: expirationTime });
}

function bearerCookieExists(): boolean {
	const cookies = new Cookies();

	return cookies.get('bearer');
}

function getToken(): string {
	if (userService.isAuthenticated) {
		return window.localStorage.getItem('bearer')!;
	}

	return '';
}