import { notification } from "antd";
import { AxiosResponse } from "axios";
import { InregistrareCentruDeProfitFormData } from "components/InregistreazaCentruDeProfit/FormularInregistrareCentruDeProfit/InregistrareCentruDeProfitFormData";
import getBackendHttpClient from "./BackendHttpClient";
import { GetCentreDeProfitLookupResponse } from "./models/centre-de-profit/GetCentreDeProfitLookupResponse";
import { GetCentreDeProfitResponse } from "./models/centre-de-profit/GetCentreDeProfitResponse";

export const centreDeProfitService = {
	inregistreaza: inregistreaza,
	get: get,
	getLookup: getLookup,
	sterge: sterge
}

const api = getBackendHttpClient();

function inregistreaza(formData: InregistrareCentruDeProfitFormData, history: any) {
	api.post('/centreDeProfit', {
		cod: formData.cod,
		denumire: formData.denumire,
		beneficiar: formData.beneficiar,
	})
		.then(function () {
			history.push('/welcome');

			notification.success({
				message: 'Info',
				description: 'Centrul de profit a fost adaugat in baza de date.'
			});
		})
		.catch(function () {
			notification.error({
				message: 'Eroare de la server',
				description: 'A avut loc o eroare pe server si centrul de profit nu a fost salvat',
			});
		});
}

function get(): Promise<AxiosResponse<GetCentreDeProfitResponse>> {
	return api.get('centreDeProfit');
}

function getLookup(): Promise<AxiosResponse<GetCentreDeProfitLookupResponse>> {
	return api.get('centreDeProfit/lookup');
}

function sterge(centruDeProfitId: string): Promise<void>  {
	return api.delete(`centreDeProfit/${centruDeProfitId}`);
}