import { AxiosResponse } from "axios";
import getBackendHttpClient from "./BackendHttpClient";
import { GetSurseAlimentareCombustibilLookupResponse } from "./models/surse-alimentare-combustibil/GetSurseAlimentareCombustibilLookupResponse";

export const surseAlimentareCombustibilService = {
	getLookup: getLookup
}

function getLookup(): Promise<AxiosResponse<GetSurseAlimentareCombustibilLookupResponse>> {
	const api = getBackendHttpClient();

	return api.get('surseAlimentareCombustibil/lookup');
}