import { Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';

import FormularIntroducereDateContor from './FormularIntroducereDateContor/FormularIntroducereDateContor';
import './IntroducereDateContor.scss';

function IntroducereDateContor(): JSX.Element {
    return (
        <div className="inregistreaza-date-contor">
            <div className="inregistreaza-date-contor__box">
                <Typography>
                    <Title>Formular introducere contor la final de luna</Title>
                    <Title level={5}>
                        Completeaza datele contorului la finalul lunii selectate prin formularul de mai jos
                    </Title>
                    <FormularIntroducereDateContor />
                </Typography>
            </div>
        </div>
    );
}

export default IntroducereDateContor;
