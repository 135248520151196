import { Button, Form, Input, Space, Typography } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { salariatiService } from 'services/SalariatiService';

import './FormularInregistrareSalariat.scss';
import { InregistreazaSalariatFormData } from './InregistrazaSalariatFormData';

const formLayout = {
    labelCol: { span: 4, offset: 2 },
    wrapperCol: { span: 12 },
};

function FormularInregistrareSalariat(): JSX.Element {
    const history = useHistory();

    const onFinish = (values: InregistreazaSalariatFormData) => {
        salariatiService.inregistreaza(values, history);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="formular-inregistrare">
            <Typography>
                <Paragraph>
                    Inainte de a putea exporta date din sistem trebuie sa avem inregistrati salariatii firmei Dvs.
                    Completati datele de mai jos, stiind ca toate campurile cu steluta rosie sunt obligatorii.
                </Paragraph>
            </Typography>

            <Form
                {...formLayout}
                name="inregistrareSalariatForm"
                className="formular-inregistrare__form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Cod salariat"
                    name="cod"
                    rules={[{ required: true, message: 'Fiecare salariat trebuie sa aiba un cod' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Nume"
                    name="nume"
                    rules={[{ required: true, message: 'Numele salariatului este obligatoriu' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Prenume"
                    name="prenume"
                    rules={[{ required: true, message: 'Prenumele salariatului este obligatoriu' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="CNP"
                    name="cnp"
                    rules={[{ required: true, message: 'CNP-ul salariatului este obligatoriu' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Functie"
                    name="functie"
                    rules={[{ required: true, message: 'Functia salariatului este obligatoriu' }]}
                >
                    <Input />
                </Form.Item>

                <Space size={[8, 8]} wrap className="formular-inregistrare__form__buttons">
                    <Button type="text" onClick={() => history.push('/welcome')}>
                        Inapoi la pagina principala
                    </Button>
                    <Button htmlType="submit">Inregistreaza salariat</Button>
                </Space>
            </Form>
        </div>
    );
}

export default FormularInregistrareSalariat;
