import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, notification, Popconfirm, Space, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { centreDeProfitService } from 'services/CentruDeProfitService';
import { CentruDeProfit } from 'services/models/centre-de-profit/CentruDeProfit';

import './VizualizareCentreDeProfit.scss';

const pagination = {
    current: 1,
    pageSize: 10,
};

const columns = [
    {
        title: 'Cod',
        dataIndex: 'cod',
        key: 'cod',
    },
    {
        title: 'Denumire',
        dataIndex: 'denumire',
        key: 'denumire',
    },
    {
        title: 'Beneficiar',
        dataIndex: 'beneficiar',
        key: 'beneficiar',
    },
    {
        title: 'Actiune',
        key: 'action',
        render: function actionColumn(text: string, row: CentruDeProfit) {
            return (
                <Space size="middle">
                    <Popconfirm
                        title="Esti sigur ca vrei sa stergi?"
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        onConfirm={() =>
                            centreDeProfitService.sterge(row.id).then(() => {
                                notification.success({
                                    message: 'Info',
                                    description: 'Centrul de profit a fost sters.',
                                });

                                window.location.reload(false);
                            })
                        }
                        okText="Da"
                        cancelText="Nu"
                    >
                        <Button danger>Sterge</Button>
                    </Popconfirm>
                </Space>
            );
        },
    },
];

function VizualizareCentreDeProfit() {
    const [centreDeProfit, setCentreDeProfit] = useState<CentruDeProfit[]>([]);
    // const history = useHistory();

    const loadCentreDeProfit = useCallback(async () => {
        const response = await centreDeProfitService.get();

        if (response.status === 200) {
            setCentreDeProfit(response.data.centreDeProfit);
        }
    }, []);

    useEffect(() => {
        loadCentreDeProfit();
    }, [loadCentreDeProfit]);

    const history = useHistory();

    return (
        <div className="vizualizare-centre-de-profit">
            <div className="vizualizare-centre-de-profit__box">
                <Button onClick={() => history.push('welcome')} style={{ marginBottom: 16 }}>
                    Inapoi la pagina principala
                </Button>

                <Table columns={columns} dataSource={centreDeProfit} pagination={pagination} />
            </div>
        </div>
    );
}

export default VizualizareCentreDeProfit;
