import { notification } from "antd";
import { AxiosResponse } from "axios";
import { InregistreazaSalariatFormData } from "components/InregistreazaSalariat/FormularInregistrareSalariat/InregistrazaSalariatFormData";
import getBackendHttpClient from "./BackendHttpClient";
import { GetSalariatiResponse } from "./models/salariati/GetSalariatiResponse";
import { GetSalariatLookupResponse } from "./models/salariati/GetSalariatLookupResponse";

export const salariatiService = {
	inregistreaza: inregistreaza,
	getLookup: getLookup,
	get: get,
	sterge: sterge
}

const api = getBackendHttpClient();

function inregistreaza(formData: InregistreazaSalariatFormData, history: any) {
	api.post('/salariati', {
		cod: formData.cod,
		nume: formData.nume,
		prenume: formData.prenume,
		cnp: formData.cnp,
		functie: formData.functie
	})
		.then(function () {
			history.push('/welcome');

			notification.success({
				message: 'Info',
				description: 'Salariatul a fost adaugat in baza de date.'
			});
		})
		.catch(function () {
			notification.error({
				message: 'Eroare de la server',
				description: 'A avut loc o eroare pe server si salariatul nu a fost salvat',
			});
		});
}

function getLookup(): Promise<AxiosResponse<GetSalariatLookupResponse>> {
	return api.get('salariati/lookup');
}

function get(): Promise<AxiosResponse<GetSalariatiResponse>>  {
	return api.get('salariati');
}

function sterge(idSalariat: string): Promise<void>  {
	return api.delete(`salariati/${idSalariat}`);
}