import { Button, Form, Input, Space, Typography } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, {  } from 'react';
import { useHistory } from 'react-router-dom';
import { centreDeProfitService } from 'services/CentruDeProfitService';

import './FormularInregistrareCentruDeProfit.scss';
import { InregistrareCentruDeProfitFormData } from './InregistrareCentruDeProfitFormData';


const formLayout = {
    labelCol: { span: 6, offset: 2 },
    wrapperCol: { span: 12 },
};

function FormularInregistrareCentruDeProfit(): JSX.Element {
    const history = useHistory();

    const onFinish = (values: InregistrareCentruDeProfitFormData) => {
        centreDeProfitService.inregistreaza(values, history);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="formular-inregistrare">
            <Typography>
                <Paragraph>
                    Trebuie sa inregistrezi centrele tale de profit pentru a putea exporta date mai intai. Campurile cu
                    steluta rosie sunt obligatorii.
                </Paragraph>
            </Typography>

            <Form
                {...formLayout}
                name="inregistrareCentruProfitForm"
                className="formular-inregistrare__form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Cod"
                    name="cod"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Input />
                </Form.Item>

				<Form.Item
                    label="Denumire"
                    name="denumire"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Input />
                </Form.Item>

				<Form.Item
                    label="Beneficiar"
                    name="beneficiar"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Input />
                </Form.Item>

                <Space size={[8, 8]} wrap className="formular-inregistrare__form__buttons">
                    <Button type="text" onClick={() => history.push('/welcome')}>
                        Inapoi la pagina principala
                    </Button>

                    <Button htmlType="submit">Inregistreaza centru de profit</Button>
                </Space>
            </Form>
        </div>
    );
}

export default FormularInregistrareCentruDeProfit;
