import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, notification, Popconfirm, Space, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { salariatiService } from 'services/SalariatiService';
import { Salariat } from 'services/models/salariati/Salariat';

import './VizualizareSalariati.scss';

const pagination = {
    current: 1,
    pageSize: 10,
};

const columns = [
    {
        title: 'Cod',
        dataIndex: 'cod',
        key: 'cod',
    },
    {
        title: 'Nume',
        dataIndex: 'nume',
        key: 'nume',
    },
    {
        title: 'Prenume',
        dataIndex: 'prenume',
        key: 'prenume',
    },
    {
        title: 'Cnp',
        dataIndex: 'cnp',
        key: 'cnp',
    },
    {
        title: 'Functie',
        dataIndex: 'functie',
        key: 'functie',
    },
    {
        title: 'Actiune',
        key: 'action',
        render: function actionColumn(text: string, row: Salariat) {
            return (
                <Space size="middle">
                    <Popconfirm
                        title="Esti sigur ca vrei sa stergi?"
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        onConfirm={() =>
                            salariatiService.sterge(row.id).then(() => {
                                notification.success({
                                    message: 'Info',
                                    description: 'Salariatul a fost sters.',
                                });

                                window.location.reload(false);
                            })
                        }
                        okText="Da"
                        cancelText="Nu"
                    >
                        <Button danger>Sterge</Button>
                    </Popconfirm>
                </Space>
            );
        },
    },
];

function VizualizareSalariati() {
    const [salariati, setSalariati] = useState<Salariat[]>([]);

    const loadSalariati = useCallback(async () => {
        const response = await salariatiService.get();

        if (response.status === 200) {
            setSalariati(response.data.salariati);
        }
    }, []);

    useEffect(() => {
        loadSalariati();
    }, [loadSalariati]);

    const history = useHistory();

    return (
        <div className="vizualizare-salariati">
            <div className="vizualizare-salariati__box">
                <Button onClick={() => history.push('welcome')} style={{ marginBottom: 16 }}>
                    Inapoi la pagina principala
                </Button>

                <Table columns={columns} dataSource={salariati} pagination={pagination} />
            </div>
        </div>
    );
}

export default VizualizareSalariati;
