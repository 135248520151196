import { Button, DatePicker, Form, Input, Select, Space, Typography } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { centreDeProfitService } from 'services/CentruDeProfitService';
import { foiDeConsumService } from 'services/FoiDeConsumService';
import { salariatiService } from 'services/SalariatiService';
import { LookupItem } from 'services/models/LookupItem';

import './FormularInregistrareFoaieDeConsum.scss';
import { InregistreazaFoaieDeConsumFormData } from './InregistreazaFoaieDeConsumFormData';
import { echipamenteService } from 'services/EchipamenteService';

const { Option } = Select;

const formLayout = {
    labelCol: { span: 6, offset: 2 },
    wrapperCol: { span: 12 },
};

function FormularInregistrareFoaieDeConsum(): JSX.Element {
    const history = useHistory();

    const [salariati, setSalariati] = useState<LookupItem[]>([]);
    const [centreDeProfit, setCentreDeProfit] = useState<LookupItem[]>([]);
    const [echipamente, setEchipamente] = useState<LookupItem[]>([]);

    const loadSalariati = useCallback(async () => {
        const response = await salariatiService.getLookup();

        if (response.status === 200) {
            setSalariati(response.data.salariati);
        }
    }, []);

    const loadCentreDeProfit = useCallback(async () => {
        const response = await centreDeProfitService.getLookup();

        if (response.status === 200) {
            setCentreDeProfit(response.data.centreDeProfit);
        }
    }, []);

    const loadEchipamente = useCallback(async () => {
        const response = await echipamenteService.getLookup();

        if (response.status === 200) {
            setEchipamente(response.data.echipamente);
        }
    }, []);

    useEffect(() => {
        loadSalariati();
        loadCentreDeProfit();
        loadEchipamente();
    }, [loadSalariati, loadCentreDeProfit, loadEchipamente]);

    const onFinish = (values: InregistreazaFoaieDeConsumFormData) => {
        foiDeConsumService.inregistreaza(values, history);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="formular-inregistrare">
            <Typography>
                <Paragraph>
                    Folosind formularul de mai jos poti sa inregistrezi o foaie de consum pentru o data specificata de
                    tine. Campurile cu steluta rosie sunt obligatorii.
                </Paragraph>
            </Typography>

            <Form
                {...formLayout}
                name="inregistrareFoaieDeConsum"
                className="formular-inregistrare__form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Data"
                    name="data"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <DatePicker />
                </Form.Item>

                <Form.Item
                    label="Echipament"
                    name="echipament"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Select>
                        {echipamente.map((echipamente, index) => (
                            <Option key={index} value={echipamente.id}>
                                {echipamente.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Contor initial"
                    name="contorInitial"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Contor final"
                    name="contorFinal"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Sofer"
                    name="sofer"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Select>
                        {salariati.map((salariat, index) => (
                            <Option key={index} value={salariat.id}>
                                {salariat.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Centru de profit"
                    name="centruDeProfit"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Select>
                        {centreDeProfit.map((centruDeProfit, index) => (
                            <Option key={index} value={centruDeProfit.id}>
                                {centruDeProfit.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Observatii"
                    name="observatii"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Input />
                </Form.Item>

                <Space size={[8, 8]} wrap className="formular-inregistrare__form__buttons">
                    <Button type="text" onClick={() => history.push('/welcome')}>
                        Inapoi la pagina principala
                    </Button>

                    <Button htmlType="submit">Inregistreaza foaie de consum</Button>
                </Space>
            </Form>
        </div>
    );
}

export default FormularInregistrareFoaieDeConsum;
