import { notification } from "antd";
import { InregistreazaFoaieDeConsumFormData } from "components/InregistreazaFoaieDeConsum/FormularInregistreazaFoaieDeConsum/InregistreazaFoaieDeConsumFormData";
import getBackendHttpClient from "./BackendHttpClient";

export const foiDeConsumService = {
	inregistreaza: inregistreaza
}

function inregistreaza(formData: InregistreazaFoaieDeConsumFormData, history: any) {
	const api = getBackendHttpClient();

	api.post('/foiDeConsum', formData)
		.then(function () {
			history.push('/welcome');

			notification.success({
				message: 'Info',
				description: 'Am inregistrat foaia de consum.'
			});
		})
		.catch(function () {
			notification.error({
				message: 'Eroare de la server',
				description: 'A avut loc o eroare pe server si foaia de consum nu a fost salvata.',
			});
		});
}