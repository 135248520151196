import { Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';

import './AlimenteazaCombustibil.scss';
import FormularAlimentareCombustibil from './FormularAlimentareCombustibil/FormularAlimentareCombustibil';

function AlimenteazaCombustibil(): JSX.Element {
    return (
        <div className="inregistreaza-alimentare-combustibil">
            <div className="inregistreaza-alimentare-combustibil__box">
                <Typography>
                    <Title>Formular alimentare combustibil</Title>
                    <Title level={5}>
                        Completeaza datele de alimentare cu combustibil a unui echipament in formularul de mai jos
                    </Title>
                    <FormularAlimentareCombustibil />
                </Typography>
            </div>
        </div>
    );
}

export default AlimenteazaCombustibil;
