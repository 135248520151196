import { Button, DatePicker, Form, Input, Select, Space, Typography } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { echipamenteService } from 'services/EchipamenteService';
import { LookupItem } from 'services/models/LookupItem';

import { AlimentareCombustibilFormData } from './AlimentareCombustibilFormData';
import './FormularAlimentareCombustibil.scss';
import { surseAlimentareCombustibilService } from 'services/SurseAlimentareCombustibilService';
import { alimentareCombustibilService } from 'services/AlimentareCombustibilService';

const { Option } = Select;

const formLayout = {
    labelCol: { span: 6, offset: 2 },
    wrapperCol: { span: 12 },
};

function FormularAlimentareCombustibil(): JSX.Element {
    const history = useHistory();

    const [echipamente, setEchipamente] = useState<LookupItem[]>([]);
    const [surseAlimentareCombustibil, setSurseAlimentareCombustibil] = useState<LookupItem[]>([]);

    const loadSurseAlimentareCombustibil = useCallback(async () => {
        const response = await surseAlimentareCombustibilService.getLookup();

        if (response.status === 200) {
            setSurseAlimentareCombustibil(response.data.surseAlimentareCombustibil);
        }
    }, []);

    const loadEchipamente = useCallback(async () => {
        const response = await echipamenteService.getLookup();

        if (response.status === 200) {
            setEchipamente(response.data.echipamente);
        }
    }, []);

    useEffect(() => {
        loadSurseAlimentareCombustibil();
        loadEchipamente();
    }, [loadSurseAlimentareCombustibil, loadEchipamente]);

    const onFinish = (values: AlimentareCombustibilFormData) => {
        alimentareCombustibilService.alimenteaza(values, history);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="formular-inregistrare">
            <Typography>
                <Paragraph>
                    Folosind formularul de mai jos poti sa alegi un echipament si sa efectuezi o alimentare de
                    combustibil pentru o data specificata de tine, la o sursa de alimentare combustibil care ai
                    introdus-o in sistem
                </Paragraph>
            </Typography>

            <Form
                {...formLayout}
                name="alimentareCombustibilForm"
                className="formular-inregistrare__form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Data"
                    name="data"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <DatePicker />
                </Form.Item>

                <Form.Item
                    label="Echipament"
                    name="echipament"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Select>
                        {echipamente.map((echipamente, index) => (
                            <Option key={index} value={echipamente.id}>
                                {echipamente.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Litri alimentati"
                    name="litriAlimentati"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Input />
                </Form.Item>

				<Form.Item
                    label="Sursa alimentare"
                    name="sursaAlimentareCombustibil"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Select>
                        {surseAlimentareCombustibil.map((sursaAlimentare, index) => (
                            <Option key={index} value={sursaAlimentare.id}>
                                {sursaAlimentare.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Space size={[8, 8]} wrap className="formular-inregistrare__form__buttons">
                    <Button type="text" onClick={() => history.push('/welcome')}>
                        Inapoi la pagina principala
                    </Button>

                    <Button htmlType="submit">Alimenteaza</Button>
                </Space>
            </Form>
        </div>
    );
}

export default FormularAlimentareCombustibil;
