import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { userService } from "./UserService";

const FileDownload = require('js-file-download');

export const rapoarteService = {
	getConsumMediu: getConsumMediu,
	getRepartizareCheltuieliPeCentreDeProfit: getRepartizareCheltuieliPeCentreDeProfit
}

function getRequestConfiguration(endpoint: string): AxiosRequestConfig {
	return {
		url: `${process.env.REACT_APP_API_SERVER_URL}/${endpoint}`,
		headers: { Authorization: `Bearer ${userService.getToken()}` },
		method: 'GET',
		responseType: 'blob'
	}
}

function getConsumMediu() {
	const requestHeaders = getRequestConfiguration('rapoarte/consum-mediu-echipament');

	axios(requestHeaders).then((response: AxiosResponse) => {
		FileDownload(response.data, 'raport consum mediu.csv');
	});
}

function getRepartizareCheltuieliPeCentreDeProfit() {
	const requestHeaders = getRequestConfiguration('rapoarte/repartizare-cheltuieli-pe-centre-de-profit');

	axios(requestHeaders).then((response: AxiosResponse) => {
		FileDownload(response.data, 'raport repartizare cheltuieli pe centre de profit.csv');
	});
}
