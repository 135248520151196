import { AxiosResponse } from "axios";
import getBackendHttpClient from "./BackendHttpClient";
import { GetTipuriCombustibilLookupResponse } from "./models/GetTipuriCombustibilLookupResponse";

export const tipuriCombustibilService = {
	getLookup: getLookup
}

function getLookup(): Promise<AxiosResponse<GetTipuriCombustibilLookupResponse>> {
	const api = getBackendHttpClient();

	return api.get('tipuriCombustibil/lookup');
}