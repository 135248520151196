import { Button, Form, Input, Select, Space, Typography } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { echipamenteService } from 'services/EchipamenteService';
import { salariatiService } from 'services/SalariatiService';
import { tipuriCombustibilService } from 'services/TipuriCombustibilService';
import { tipuriEchipamentService } from 'services/TipuriEchipamentService';
import { LookupItem } from 'services/models/LookupItem';

import './FormularInregistrareEchipament.scss';
import { InregistreazaEchipamentFormData } from './InregistrazaEchipamentFormData';

const { Option } = Select;

const formLayout = {
    labelCol: { span: 6, offset: 2 },
    wrapperCol: { span: 12 },
};

function FormularInregistrareEchipament(): JSX.Element {
    const [salariati, setSalariati] = useState<LookupItem[]>([]);
    const [tipuriCombustibil, setTipuriCombustibil] = useState<LookupItem[]>([]);
    const [tipuriEchipament, setTipuriEchipament] = useState<LookupItem[]>([]);
    const history = useHistory();

    const loadSalariati = useCallback(async () => {
        const response = await salariatiService.getLookup();

        if (response.status === 200) {
            setSalariati(response.data.salariati);
        }
    }, []);

    const loadTipuriCombustibil = useCallback(async () => {
        const response = await tipuriCombustibilService.getLookup();

        if (response.status === 200) {
            setTipuriCombustibil(response.data.tipuriCombustibil);
        }
    }, []);

    const loadTipuriEchipament = useCallback(async () => {
        const response = await tipuriEchipamentService.getLookup();

        if (response.status === 200) {
            setTipuriEchipament(response.data.tipuriEchipament);
        }
    }, []);

    useEffect(() => {
        loadSalariati();
        loadTipuriCombustibil();
        loadTipuriEchipament();
    }, [loadSalariati, loadTipuriCombustibil, loadTipuriEchipament]);

    const onFinish = (values: InregistreazaEchipamentFormData) => {
        echipamenteService.inregistreaza(values, history);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="formular-inregistrare">
            <Typography>
                <Paragraph>
                    Inainte de a putea exporta date din sistem trebuie sa avem inregistrat echipamentul firmei Dvs.
                    Completati datele de mai jos, stiind ca toate campurile cu steluta rosie sunt obligatorii.
                </Paragraph>
            </Typography>

            <Form
                {...formLayout}
                name="inregistrareEchipamentForm"
                className="formular-inregistrare__form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Serie identificare"
                    name="serieIdentificare"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item label="Cod" name="cod" rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Tip echipament"
                    name="tip"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Select>
                        {tipuriEchipament.map((tipEchipament, index) => (
                            <Option key={index} value={tipEchipament.id}>
                                {tipEchipament.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Proprietar"
                    name="proprietar"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Select>
                        {salariati.map((salariat, index) => (
                            <Option key={index} value={salariat.id}>
                                {salariat.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Numar Inmatriculare"
                    name="nrInmatriculare"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Numar Inventar"
                    name="nrInventar"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Tip Combustibil"
                    name="tipCombustibil"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Select>
                        {tipuriCombustibil.map((tipCombustibil, index) => (
                            <Option key={index} value={tipCombustibil.id}>
                                {tipCombustibil.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Unitatea de masura"
                    name="unitateMasuraContor"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Select>
                        <Option value="Ore">Ore</Option>
                        <Option value="Km">Km</Option>
                    </Select>
                </Form.Item>

                <Space size={[8, 8]} wrap className="formular-inregistrare__form__buttons">
                    <Button type="text" onClick={() => history.push('/welcome')}>
                        Inapoi la pagina principala
                    </Button>
                    <Button htmlType="submit">Inregistreaza echipament</Button>
                </Space>
            </Form>
        </div>
    );
}

export default FormularInregistrareEchipament;
