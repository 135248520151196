import { Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import FormularInregistrareEchipament from './FormularInregistrareEchipament/FormularInregistrareEchipament';

import './InregistreazaEchipament.scss';

function InregistreazaEchipament(): JSX.Element {
    return (
        <div className="inregistreaza-echipament">
            <div className="inregistreaza-echipament__box">
                <Typography>
                    <Title>Inregistreaza Echipament</Title>
                    <Title level={5}>Completeaza formularul de mai jos pentru a inregistra un echipament</Title>
                    <FormularInregistrareEchipament />
                </Typography>
            </div>
        </div>
    );
}

export default InregistreazaEchipament;
