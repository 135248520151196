import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, notification, Popconfirm, Space, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { echipamenteService } from 'services/EchipamenteService';
import { Echipament } from 'services/models/echipamente/Echipament';

import './VizualizareEchipamente.scss';

const pagination = {
    current: 1,
    pageSize: 10,
};

const columns = [
    {
        title: 'Serie identificare',
        dataIndex: 'serieIdentificare',
        key: 'serieIdentificare',
    },
    {
        title: 'Cod',
        dataIndex: 'cod',
        key: 'cod',
    },
    {
        title: 'Tip',
        dataIndex: 'tip',
        key: 'tip',
    },
    {
        title: 'Proprietar',
        dataIndex: 'numeProprietar',
        key: 'numeProprietar',
    },
    {
        title: 'Nr inmatriculare',
        dataIndex: 'nrInmatriculare',
        key: 'nrInmatriculare',
    },
    {
        title: 'Nr inventar',
        dataIndex: 'nrInventar',
        key: 'nrInventar',
    },
    {
        title: 'Tip combustibil',
        dataIndex: 'tipCombustibil',
        key: 'tipCombustibil',
    },
    {
        title: 'Unitate masura contor',
        dataIndex: 'unitateMasuraContor',
        key: 'unitateMasuraContor',
    },
    {
        title: 'Actiune',
        key: 'action',
        render: function actionColumn(text: string, row: Echipament) {
            return (
                <Space size="middle">
                    <Popconfirm
                        title="Esti sigur ca vrei sa stergi?"
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        onConfirm={() =>
                            echipamenteService.sterge(row.id).then(() => {
                                notification.success({
                                    message: 'Info',
                                    description: 'Echipamentul a fost sters.',
                                });

                                window.location.reload(false);
                            })
                        }
                        okText="Da"
                        cancelText="Nu"
                    >
                        <Button danger>Sterge</Button>
                    </Popconfirm>
                </Space>
            );
        },
    }
];

function VizualizareEchipamente() {
    const [echipamente, setEchipamente] = useState<Echipament[]>([]);
    // const history = useHistory();

    const loadEchipamente = useCallback(async () => {
        const response = await echipamenteService.get();

        if (response.status === 200) {
            setEchipamente(response.data.echipamente);
        }
    }, []);

    useEffect(() => {
        loadEchipamente();
    }, [loadEchipamente]);

    const history = useHistory();

    return (
        <div className="vizualizare-echipamente">
            <div className="vizualizare-echipamente__box">
                <Button onClick={() => history.push('welcome')} style={{ marginBottom: 16 }}>
                    Inapoi la pagina principala
                </Button>

                <Table columns={columns} dataSource={echipamente} pagination={pagination} />
            </div>
        </div>
    );
}

export default VizualizareEchipamente;
