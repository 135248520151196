import axios from "axios";
import { userService } from "./UserService";

export default function getBackendHttpClient() {
	const axiosInstance = axios.create({
		baseURL: process.env.REACT_APP_API_SERVER_URL,
		headers: { Authorization: `Bearer ${userService.getToken()}` }
	});

	return axiosInstance
}