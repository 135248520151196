import { Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';

import FormularInregistrareCentruDeProfit from './FormularInregistrareCentruDeProfit/FormularInregistrareCentruDeProfit';
import './InregistreazaCentruDeProfit.scss';

function InregistreazaCentruDeProfit(): JSX.Element {
    return (
        <div className="inregistreaza-centru-profit">
            <div className="inregistreaza-centru-profit__box">
                <Typography>
                    <Title>Inregistreaza Centru de Profit</Title>
                    <Title level={5}>
                        Completeaza formularul de mai jos pentru a inregistra un centru de profit al companiei tale
                    </Title>
                    <FormularInregistrareCentruDeProfit />
                </Typography>
            </div>
        </div>
    );
}

export default InregistreazaCentruDeProfit;
