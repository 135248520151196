import { Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';

import FormularInregistrareCentruDeProfit from './FormularInregistreazaFoaieDeConsum/FormularInregistrareFoaieDeConsum';
import './InregistreazaFoaieDeConsum.scss';

function InregistreazaFoaieDeConsum(): JSX.Element {
    return (
        <div className="inregistreaza-foaie-de-consum">
            <div className="inregistreaza-foaie-de-consum__box">
                <Typography>
                    <Title>Inregistreaza Foaie de Consum</Title>
                    <Title level={5}>
                        Completeaza formularul de mai jos pentru a inregistra o foaie de consum
                    </Title>
                    <FormularInregistrareCentruDeProfit />
                </Typography>
            </div>
        </div>
    );
}

export default InregistreazaFoaieDeConsum;
