import { notification } from "antd";
import { AxiosResponse } from "axios";
import { InregistreazaEchipamentFormData } from "components/InregistreazaEchipament/FormularInregistrareEchipament/InregistrazaEchipamentFormData";
import getBackendHttpClient from "./BackendHttpClient";
import { GetEchipamenteLookupResponse } from "./models/echipamente/GetEchipamenteLookupResponse";
import { GetEchipamenteResponse } from "./models/echipamente/GetEchipamenteResponse";

export const echipamenteService = {
	inregistreaza: inregistreaza,
	get: get,
	getLookup: getLookup,
	sterge: sterge
}

const api = getBackendHttpClient();

function inregistreaza(formData: InregistreazaEchipamentFormData, history: any) {
	api.post('/echipamente', {
		serieIdentificare: formData.cod,
		cod: formData.cod,
		tip: formData.tip,
		proprietar: formData.proprietar,
		nrInmatriculare: formData.nrInmatriculare,
		nrInventar: formData.nrInventar,
		tipCombustibil: formData.tipCombustibil,
		unitateMasuraContor: formData.unitateMasuraContor,
	})
		.then(function () {
			history.push('/welcome');

			notification.success({
				message: 'Info',
				description: 'Echipamentul a fost adaugat in baza de date.'
			});
		})
		.catch(function () {
			notification.error({
				message: 'Eroare de la server',
				description: 'A avut loc o eroare pe server si echipamentul nu a fost salvat',
			});
		});
}

function get(): Promise<AxiosResponse<GetEchipamenteResponse>> {
	return api.get('echipamente');
}

function getLookup(): Promise<AxiosResponse<GetEchipamenteLookupResponse>> {
	return api.get('echipamente/lookup');
}

function sterge(idEchipament: string): Promise<void>  {
	return api.delete(`echipamente/${idEchipament}`);
}