import { notification } from "antd";
import { AlimentareCombustibilFormData } from "components/AlimenteazaCombustibil/FormularAlimentareCombustibil/AlimentareCombustibilFormData";
import getBackendHttpClient from "./BackendHttpClient";

export const alimentareCombustibilService = {
	alimenteaza: alimenteaza
}

function alimenteaza(formData: AlimentareCombustibilFormData, history: any) {
	const api = getBackendHttpClient();

	api.post('/alimentariCombustibil', formData)
		.then(function () {
			history.push('/welcome');

			notification.success({
				message: 'Info',
				description: 'Am inregistrat alimentarea de combustibil.'
			});
		})
		.catch(function () {
			notification.error({
				message: 'Eroare de la server',
				description: 'A avut loc o eroare pe server si alimentarea de combustibil nu a fost salvata.',
			});
		});
}