import { AxiosResponse } from "axios";
import getBackendHttpClient from "./BackendHttpClient";
import { GetTipuriEchipamentLookupResponse } from "./models/GetTipuriEchipamentLookupResponse";

export const tipuriEchipamentService = {
	getLookup: getLookup
}

function getLookup(): Promise<AxiosResponse<GetTipuriEchipamentLookupResponse>> {
	const api = getBackendHttpClient();

	return api.get('tipuriEchipament/lookup');
}