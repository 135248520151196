import { notification } from "antd";
import { IntroducereDateContorFormData } from "components/IntroducereDateContor/FormularIntroducereDateContor/IntroducereDateContorFormData";
import getBackendHttpClient from "./BackendHttpClient";

export const dateContorService = {
	inregistreaza: inregistreaza
}

function inregistreaza(formData: IntroducereDateContorFormData, history: any) {
	const api = getBackendHttpClient();

	api.post(`/dateContor/${formData.data.year()}/${formData.data.month() + 1}`, formData)
		.then(function () {
			history.push('/welcome');

			notification.success({
				message: 'Info',
				description: 'Am inregistrat datele contorului la final de luna.'
			});
		})
		.catch(function () {
			notification.error({
				message: 'Eroare de la server',
				description: 'A avut loc o eroare pe server si datele de contor nu a fost salvate.',
			});
		});
}