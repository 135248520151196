import { PlusCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import Meta from 'antd/lib/card/Meta';
import DriverIllustration from 'assets/illustrations/driver.svg';
import HouseIllustration from 'assets/illustrations/house.svg';
import VehicleIllustration from 'assets/illustrations/vehicle.svg';
import React from 'react';
import { useHistory } from 'react-router-dom';

import './CardsWrapper.scss';
import './CardsWrapper.responsive.scss';

function CardWrapper(): JSX.Element {
    const history = useHistory();

    return (
        <div className="cards-wrapper">
            <Card
                className="cards-wrapper__card"
                actions={[
                    <PlusCircleOutlined key="add" onClick={() => history.push('/inregistrare-salariat')} />,
                    <UnorderedListOutlined key="view" onClick={() => history.push('/salariati')} />,
                ]}
            >
                <img src={DriverIllustration} className="cards-wrapper__card__illustration" />
                <Meta
                    title="Salariati"
                    description="Salariatii reprezinta personalul de care poti lega echipamente, aici poti sa ii vizualizezi sau sa inregistrezi"
                />
            </Card>

            <Card
                className="cards-wrapper__card"
                actions={[
                    <PlusCircleOutlined key="add" onClick={() => history.push('/inregistrare-echipament')} />,
                    <UnorderedListOutlined key="view" onClick={() => history.push('/echipamente')} />,
                ]}
            >
                <img src={VehicleIllustration} className="cards-wrapper__card__illustration" />
                <Meta
                    title="Echipamente"
                    description="Aici inregistrezi echipamentele tale cum ar fi autoturisme, utilaje, etc, pentru a fi folosite in export"
                />
            </Card>

            <Card
                className="cards-wrapper__card"
                actions={[
                    <PlusCircleOutlined key="add" onClick={() => history.push('/inregistrare-centru-de-profit')} />,
                    <UnorderedListOutlined key="view" onClick={() => history.push('/centre-de-profit')} />,
                ]}
            >
                <img src={HouseIllustration} className="cards-wrapper__card__illustration" />
                <Meta
                    title="Centre de profit"
                    description="Centrele de profit sunt proiectele afacerii tale, folosite pentru a categorisi datele din platforma"
                />
            </Card>
        </div>
    );
}

export default CardWrapper;
