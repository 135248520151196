import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { rapoarteService } from 'services/RapoarteService';

import CardWrapper from './CardWrapper/CardWrapper';
import './WelcomePage.responsive.scss';
import './WelcomePage.scss';

const butoaneIngestieDate = [
    {
        text: 'Introdu foaie de consum',
        url: '/inregistrare-foaie-de-consum',
    },
    {
        text: 'Introdu date alimentare combustibil',
        url: '/alimentare-combustibil',
    },
    {
        text: 'Introdu contor la final de luna',
        url: '/introducere-contor-final-luna',
    },
];

const butoaneRapoarte = [
	{
		text: 'Descarca raport consum mediu echipament',
		callback: rapoarteService.getConsumMediu
	},
	{
		text: 'Descarca raport repartizare cheltuieli pe centre de profit',
		callback: rapoarteService.getRepartizareCheltuieliPeCentreDeProfit
	}
]

function WelcomePage() {
    const history = useHistory();

    return (
        <div className="welcome-page">
            <div className="welcome-page__content">
                <CardWrapper />

                <div className="welcome-page__content__buttons">
                    {butoaneIngestieDate.map((buton, index) => (
                        <Button
                            key={index}
                            className="welcome-page__content__buttons__button"
                            onClick={() => history.push(buton.url)}
                        >
                            {buton.text}
                        </Button>
                    ))}
                </div>

				<div className="welcome-page__content__buttons">
                    {butoaneRapoarte.map((buton, index) => (
                        <Button
                            key={index}
							type="primary"
                            className="welcome-page__content__buttons__button"
                            onClick={() => buton.callback()}
                        >
                            {buton.text}
                        </Button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default WelcomePage;
