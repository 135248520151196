import { Button, DatePicker, Form, Input, Select, Space, Typography } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { echipamenteService } from 'services/EchipamenteService';
import { LookupItem } from 'services/models/LookupItem';

import './FormularIntroducereDateContor.scss';
import { IntroducereDateContorFormData } from './IntroducereDateContorFormData';
import { dateContorService } from 'services/DateContorService';

const { Option } = Select;

const formLayout = {
    labelCol: { span: 6, offset: 2 },
    wrapperCol: { span: 12 },
};

function FormularIntroducereDateContor(): JSX.Element {
    const history = useHistory();

    const [echipamente, setEchipamente] = useState<LookupItem[]>([]);

    const loadEchipamente = useCallback(async () => {
        const response = await echipamenteService.getLookup();

        if (response.status === 200) {
            setEchipamente(response.data.echipamente);
        }
    }, []);

    useEffect(() => {
        loadEchipamente();
    }, [loadEchipamente]);

    const onFinish = (values: IntroducereDateContorFormData) => {
        dateContorService.inregistreaza(values, history);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="formular-inregistrare">
            <Typography>
                <Paragraph>
                    Folosind formularul de mai jos poti sa inregistrezi datele de contor la finalul lunii selectate.
                </Paragraph>
            </Typography>

            <Form
                {...formLayout}
                name="alimentareCombustibilForm"
                className="formular-inregistrare__form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Data"
                    name="data"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <DatePicker picker="month" />
                </Form.Item>

                <Form.Item
                    label="Echipament"
                    name="echipament"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Select>
                        {echipamente.map((echipamente, index) => (
                            <Option key={index} value={echipamente.id}>
                                {echipamente.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Stoc combustibil"
                    name="stocCombustibil"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Input />
                </Form.Item>

				<Form.Item
                    label="Contor final luna"
                    name="contorFinalLuna"
                    rules={[{ required: true, message: 'Acest camp este obligatoriu' }]}
                >
                    <Input />
                </Form.Item>

                <Space size={[8, 8]} wrap className="formular-inregistrare__form__buttons">
                    <Button type="text" onClick={() => history.push('/welcome')}>
                        Inapoi la pagina principala
                    </Button>

                    <Button htmlType="submit">Inregistreaza date contor</Button>
                </Space>
            </Form>
        </div>
    );
}

export default FormularIntroducereDateContor;
