import { Button, Form, Input } from 'antd';
import Title from 'antd/lib/typography/Title';
import Typography from 'antd/lib/typography/Typography';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { userService } from 'services/UserService';

import { LoginFormData } from './LoginFormData';
import './LoginPage.scss';

const loginFormLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
};

const buttonLayout = {
    wrapperCol: { offset: 12, span: 16 },
};

function LoginForm(): JSX.Element {
	let history = useHistory();

    const onFinish = (values: LoginFormData) => {
        userService.login(values, history);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            className="login__box__form"
            {...loginFormLayout}
            name="loginForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: 'Va rugam sa introduceti un username' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Parola"
                name="password"
                rules={[{ required: true, message: 'Va rugam sa introduceti o parola' }]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item {...buttonLayout}>
                <Button htmlType="submit">Login</Button>
            </Form.Item>
        </Form>
    );
}

function LoginPage(): JSX.Element {
    return (
        <div className="login">
            <div className="login__box">
                <Typography>
                    <Title>Login</Title>
                    <Title level={5}>Introdu datele tale de autentificare in campurile de mai jos</Title>
                    <LoginForm />
                </Typography>
            </div>
        </div>
    );
}

export default LoginPage;
